import { meCheckHandlerCreateByProductNameWithExpiredAt } from './meCheckHandlerCreateByProductName';
export const meCheckHandlerAgentWeb = meCheckHandlerCreateByProductNameWithExpiredAt('web');
export const meCheckHandlerAgentWebStock = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
/** Winner投顧版專用 台指+海期版本產品(page: winnerA) */
export const meCheckHandlerAgentWebA = meCheckHandlerCreateByProductNameWithExpiredAt('web_A');
/** Winner投顧版專用 台指指標版本產品(page: winnerB) */
export const meCheckHandlerAgentWebB = meCheckHandlerCreateByProductNameWithExpiredAt('web_B');
/** Winner投顧版專用 台指籌碼指標版本產品(page: winnerC) */
export const meCheckHandlerAgentWebC = meCheckHandlerCreateByProductNameWithExpiredAt('web_C');
/** PRO版本 目前用在asiajye投顧的bandtrade指標 */
export const meCheckHandlerAgentWebPro = meCheckHandlerCreateByProductNameWithExpiredAt('web_pro');
/** PRO版本 目前用在asiajye_stock投顧的特別選股 */
export const meCheckHandlerAgentWebStockPro = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock_pro');
/** Winner98投顧版專用 內部測試版頁面與內部策略回測頁面(page: winner98_backtest) */
export const meCheckHandlerAgentBackTest = meCheckHandlerCreateByProductNameWithExpiredAt('backtest');
/* futuresai 的 virtual exchange */
/* https://exchange.futures-ai.com/ */
/* ~/pages/virtual_exchange */
export const meCheckHandlerAgentVEReadPublicAccountRead = meCheckHandlerCreateByProductNameWithExpiredAt('read_public_account');
export const meCheckHandlerAgentAdmin = meCheckHandlerCreateByProductNameWithExpiredAt('admin');
